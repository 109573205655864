
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import { getMyData } from "../../../../api/user";
import { dataType } from "./stateType";
import { Toast } from "vant";

const states: dataType = {
  userData: {},
};
export default defineComponent({
  setup() {
    const state = reactive<dataType>(states);
    const { getUserData } = useNextwork(state);
    const { onResource, onFeedback } = useEvent(state);
    onMounted(() => {
      getUserData();
    });
    return {
      ...toRefs(state),
      onResource,
      onFeedback,
    };
  },
  components: {},
});

// 事件类hooks
const useEvent = (state: dataType) => {
  const onResource = () => {
    Toast({
      message: "敬请期待",
    });
  };

  const onFeedback = () => {
    location.href = "https://support.qq.com/product/343845";
  };

  return {
    onFeedback,
    onResource,
  };
};

// 接口相关hooks
const useNextwork = (state: dataType) => {
  const getUserData = async () => {
    const result: any = await getMyData();
    if (result.data.code == 0) {
      state.userData = result.data.data;
    }
  };
  return {
    getUserData,
  };
};
